const main = () => {
    const vimeoItems = document.querySelectorAll('.ImageGrid__Vimeo');
    const videoItemsEmbed = [];
    for (let i = 0; i < vimeoItems.length; i++) {
        const item = vimeoItems[i];
        const embed = item.firstChild;
        item.setAttribute('data-active', '0');
        item.removeChild(embed);
        videoItemsEmbed.push(embed);
    }
    const onScroll = ()=>{
        for (let i = 0; i < vimeoItems.length; i++) {
            const item = vimeoItems[i];
            const posY = item.getBoundingClientRect().y;
            const isActive = parseInt(item.getAttribute('data-active')) === 1;
            if (!isActive && posY>0 && posY<window.innerHeight) {
                item.setAttribute('data-active', '1');
                item.appendChild(videoItemsEmbed[i]);
            }
        }
    };
    window.addEventListener('scroll', ()=> {
        onScroll();
    });
    onScroll();
};
main();
